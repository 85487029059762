@import "src/styles/numbers";

.info-block-container {
    padding: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: white;
    border: 1px solid #EAEAEA;
}

.info-block-session {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 16px;

    @media (max-width: $mobile_width) {
        margin: 0;
    }
}

.text-block {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;

    &.rtl {
        flex-direction: row-reverse;
    }
}

.info-icon {
    height: 16px;
    width: 16px;
    margin-top: 2px;
    margin-right: 4px;
}

.lock-icon {
    position: relative;
    top: 2px;
    height: 14px;
    width: 14px;
    min-width: 14px;
    
    path:last-child {
        fill: goldenrod;
    }
}

.text {
    font-size: 13px;
    margin: 0;
}

@import "src/styles/colorVars";
@import "src/styles/numbers";

$tabletSmall: 1025px;
$tabletLarge: 1300px;

.chatMessageWrapper {
  position: relative;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: -10px;

  &:hover {
    [class*="send-reaction-container"] {
      display: flex;
    }
  }
}

.messageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0 5%;
  margin-top: 0px;
  min-height: 50px;
  margin-bottom: 7px;
  transition: background-color 0.2s ease-in-out;

  &.alignEnd {
    align-items: flex-end;
  }

  &.highlighted {
    background-color: $tertiary_blue;
  }

  @media (min-width: $mobile_sm_width) and (max-width: $tabletSmall) {
    padding: 0 8%;
  }

  @media (min-width: $tabletSmall) and (max-width: $tabletLarge) {
    padding: 0 5%;
  }

  @media (min-width: #{$tabletLarge + 1}) and (max-width: $desktop_width) {
    padding: 0 6.5%;
  }

  @media (min-width: $desktop_width) {
    padding: 0 8%;
  }
}

.messageBox {
  background: var(--background-color, #f3f3f3);
  border-radius: 8px;
  padding: 5px 10px;
  color: #f3f3f3;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  max-width: 80%;
  margin-bottom: 3px;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);

  &.backgroundLight {
    background: $primary_white;
  }

  @media (min-width: $mobile_sm_width) and (max-width: $tabletSmall) {
    max-width: 65%;
  }

  @media (min-width: $tabletSmall) and (max-width: $tabletLarge) {
    max-width: 80%;
  }

  @media (min-width: #{$tabletLarge + 1}) and (max-width: $desktop_width) {
    max-width: 75%;
  }

  @media (min-width: $desktop_width) {
    max-width: 65%;
  }
}

.afterFooterContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
  min-width: 100px;
  max-width: 80%;
}

.error {
  border: 1px solid $primary_red;
}

.nameText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  word-wrap: break-word;
  margin: 0;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  word-wrap: break-word;
  position: relative;
  margin-top: 4px;
  margin-bottom: 0;
  color: #353535;
}

.deletedText {
  text-decoration: line-through;
}

.receiver {
  position: relative;
  margin-bottom: 2px;
  margin-inline: 3px;
  width: 100%;
  bottom: -4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 3px;
}

.timestamp {
  letter-spacing: 0;
  float: right;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: -5px;
  color: $secondary_black;

  display: flex;
  justify-content: flex-end;
  align-self: flex-end;

}

.timestampUser {
  letter-spacing: 0;
  position: relative;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  word-wrap: break-word;
  margin-bottom: 0;
  width: 70px;
  color: $primary_white;
  display: flex;
  justify-content: flex-end;
}

.messageStatusIcon {
  height: 16px;
  width: 16px;
}

.singleTick {
  height: 12px;
  width: 12px;
  margin: 2px 0 0 4px;
}

.triangle.sender {
  content: "";
  position: absolute;
  left: 0;
  width: 0;
  height: 0;
  border: 21px solid transparent;
  border-right-color: #f3f3f3;
  border-left: 0;
  border-top: 0;
  margin-top: 0;
  margin-left: 5px;
  border-top-left-radius: 30%;
}

.triangle.user {
  content: "";
  position: absolute;
  right: 25px;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-left-color: #dcf8c6;
  border-right: 0;
  border-top: 0;
  border-top-right-radius: 30%;
  margin-top: 0px;
  margin-right: -20px;
}

@import 'src/styles/numbers';
@import 'src/styles/colorVars';

.container {
    display: flex;
    flex-direction: column;
    background-color: $tertiary_grey_bg;
    border-radius: 4px;
    cursor: pointer;

    img,
    video {
        width: 300px;
        height: 300px;
        max-height: 300px;
        object-fit: cover;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    @media (max-width: $mobile_sm_width) {
        img,
        video {
            width: 200px;
            height: 200px;
            max-height: 200px;
        }

        video {
            height: 200px;
        }
    }
}

.hostname {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $secondary_black;
    padding: 4px 6px;
}

.playButton {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width: 48px;
    height: 48px;
    cursor: pointer;
    pointer-events: none;
}

.placeholderContainer {
    svg {
        height: 48px;
        width: 48px;
    }
}

.videoContainer {
    position: relative;
    width: 100%;
    height: 100%;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.adMessageInfoBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    font-style: italic;
    font-size: 12px;
    color: $secondary_black;
    margin-bottom: 2px;
  
    svg {
      width: 12px;
      height: 12px;
    }
  }
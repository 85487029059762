@import 'src/styles/colorVars';

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 4px;
    min-width: 200px;
}

.navigateToPost {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: background-color 0.2s;
    border-radius: 8px;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $primary_grey_hover;

        .viewOnFacebookButton {
            opacity: 1;
            visibility: visible;
        }
    }
}

.postTypeTag {
    &[class*='ant-tag'] {
        width: fit-content;
        margin-bottom: 4px;
    }
}

.messageType {
    color: $secondary_black;
    font-size: 12px;

    &.outgoing {
        color: $primary_white;
    }
}

.reactionIcon {
    width: 24px;
    height: 24px;
}

.viewOnFacebookButton {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s;
    margin-right: 24px;

    button {
        cursor: pointer;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        font-weight: 600;
        background-color: #3b82f6;
        color: #ffffff;
        transition: background-color 0.2s;
        border: 1px solid transparent;
        border-radius: 0.375rem;

        &:hover {
            background-color: #2563eb;
        }
    }

    svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }

    span {
        font-size: 0.75rem;
        color: #ffffff;
    }
}
